import {
    MenuManager,
    MenuDropdown,
    MenuDropdownItems,
} from "@/clarivi/libs/ubs/menu-manager/";

// Função de permissão
function permissoes(user, menu) {
    var _permissoes = [];

    _permissoes = menu;

    var _permissoes_full = [
        "DBC_DASHBOARD",

        "CTM_CUSTOMER",
        "CTM_AGENDA",
        "CTM_RELATORIO_INTERACAO",

        "CTM_IDENTIFICACAO_MERCADO",
        //"CTM_IDENTIFICACAO_PRODUTORES",


        "CTM_INDICE_TECNOLOGICO",
        //  "CTM_PLANEJAMENTO_VENDA",

        "EST_ESTOQUE",
        "EST_DASHBOARD",
        "EST_RELATORIO",

        "VDR_VENDOR",

        "VDR_NEGOCIACAO",
        "VDR_PEDIDO",
        "VDR_TABELA_PRECOS",
        "VDR_ITENS_FATURADOS",

        "MKT_MARKET",
        "GEO_GEO",

        "CPC_CPANEL",
        "CPC_CD_CADASTRO",

        "CPC_CD_PRODUTOR",

        "CPC_CD_EMPRESA",
        "CPC_CD_TALHOES",
        "CPC_CD_AREAS_AGRICOLAS",
        "CPC_CD_PERFIL_ACESSO",
        "CPC_CD_PERFIL_MENU",
        "CPC_CD_USUARIOS",
        "CPC_CD_CARTEIRA",
        "CPC_CD_PRODUTORES",
        "CPC_CD_PROPRIEDADES",
        "CPC_CD_MAQUINAS",
        "CPC_CD_REDISTRIBUIDORES",
        "CPC_CD_AREA_DE_ATUACAO",
        "CPC_CD_MICRO_REGIOES",
        "CPC_CD_MESO_REGIOES",
        "CPC_CD_REGIOES",

        "CPC_BD_BASE_DADOS",
        "CPC_BD_CULTURAS_VINCULADAS",
        "CPC_BD_SAFRAS",
        "CPC_BD_INDEXADORES",
        "CPC_BD_PRODUTOS",
        // "CPC_BD_FILIAIS",
        "CPC_BD_UNIDADE_ATACADISTA",
        "CPC_BD_UNIDADE_REDISTRIBUIDOR",
        "CPC_BD_UNIDADE_DISTRIBUIDOR",
        "CPC_BD_FUNIL_VENDAS",
        "CPC_BD_INDICE_TECNOLOGICO", //
        "CPC_BD_STATUS_LEAD",
        "CPC_BD_MOTIVOS_RECUSA",
        "CPC_BD_TIPOS_INTERACAO",
        "CPC_BD_UNIDADES_MEDIDA",
        
        // "CPC_BD_MICRO_REGIOES",
        // "CPC_BD_MESO_REGIOES",
        // "CPC_BD_REGIOES",
        

        "CPE_CPANEL",

        "CPE_DB_DASHBOARD",

        "CPE_CD_CADASTRO",
        "CPE_CD_EMPRESAS",
        "CPE_CD_LIMITE_USUARIOS",
        "CPE_CD_PRODUTOS",
        // "CPE_CD_USUARIO_MASTER",
        "CPE_CD_USUARIO_MASTER_NOVO",
        "CPE_CD_USUARIOS",
        "CPE_CD_PERFIL_ACESSO",
        "CPE_CD_PERFIL_CONFIG",

        "CPE_BD_BASE_DADOS",
        //"CPE_BD_PRODUTOS_ELIPSE",
        "CPE_BD_MUNICIPIOS",
        "CPE_BD_CULTURAS",
        "CPE_BD_INDEXADORES",
        "CPE_BD_FORMAS_PAGAMENTO",
        "CPE_BD_MODOS_PAGAMENTOS",
        "CPE_BD_PRODUTOS",
        //"CPE_BD_PACOTE_MANUTENCAO",
        "CPE_BD_MOTIVOS_RECUSA",
        // "CPE_BD_PLANO_ACAO",
        "CPE_BD_TIPOS_INTERACAO",
        "CPE_BD_UNIDADES_MEDIDA",
        "CPE_BD_UNIDADES_NEGOCIO",

        "CPE_CF_CONFIGURACOES",
        "CPE_CF_MENU",
        "CPE_CF_PERFIL_MENU",
        "CPE_CF_USUARIO_MASTER_NOVO",
        "CPE_CF_TIPO_STATUS_ITEM_NEGOCIADO",
        "CPE_CF_FABRICANTES",
        "CPE_CF_PORTIFOLIO",
        "CPE_CF_FAMILIAS",
        "CPE_CF_MARCAS",
        "CPE_CF_CATEGORIAS",
        "CPE_CF_GRUPOS",
        "CPE_CF_PRODUTOS",
        "CPE_CF_MODULOS",
        "CPE_CF_FUNCIONALIDADES",
        "CPE_CF_PERMISSOES",

        "SPT_SUPORTE",
        "SPT_CF_CONFIGURACOES",
        "SPT_CF_SEGMENTOS",
        "SPT_CF_TIPOS_CLIENTE",
        "SPT_CF_TICKETS",
        "SPT_CF_TIPOS_STATUS_TICKET",
        "SPT_CF_TIPOS_STATUS_AGENDAMENTO",

        "SPT_CF_LOGS",
        "SPT_CF_ACESSOS",

        "SAIR",
    ];

    var permissoes_unimaq = [
        "DBC_DASHBOARD",

        "CTM_CUSTOMER",
        "CTM_AGENDA",
        "CTM_RELATORIO_INTERACAO",

        "CTM_IDENTIFICACAO_MERCADO",
        // "CTM_INDICE_TECNOLOGICO",
        // "CTM_PLANEJAMENTO_VENDA",

        "EST_ESTOQUE",
        "EST_DASHBOARD",
        "EST_RELATORIO",

        "VDR_VENDOR",

        "VDR_NEGOCIACAO",
        "VDR_PEDIDO",
        "VDR_TABELA_PRECOS",
        "VDR_ITENS_FATURADOS",

        "MKT_MARKET",
        "GEO_GEO",

        "CPC_CPANEL",
        "CPC_CD_CADASTRO",

        "CPC_CD_EMPRESA",
        "CPC_CD_TALHOES",
        "CPC_CD_AREAS_AGRICOLAS",
        "CPC_CD_PERFIL_ACESSO",
        "CPC_CD_PERFIL_MENU",
        "CPC_CD_USUARIOS",
        "CPC_CD_CARTEIRA",
        "CPC_CD_PRODUTORES",
        "CPC_CD_PROPRIEDADES",
        "CPC_CD_MAQUINAS",
        //"CPC_CD_REDISTRIBUIDORES",
        "CPC_CD_AREA_DE_ATUACAO",
        "CPC_CD_MICRO_REGIOES",
        "CPC_CD_MESO_REGIOES",
        "CPC_CD_REGIOES",

        "CPC_BD_BASE_DADOS",
        "CPC_BD_CULTURAS_VINCULADAS",
        "CPC_BD_SAFRAS",
        "CPC_BD_INDEXADORES",
        "CPC_BD_PRODUTOS",
        // "CPC_BD_FILIAIS",
        "CPC_BD_UNIDADE_ATACADISTA",
        "CPC_BD_UNIDADE_REDISTRIBUIDOR", 
        "CPC_BD_UNIDADE_DISTRIBUIDOR",       
        "CPC_BD_FUNIL_VENDAS",
        // "CPC_BD_INDICE_TECNOLOGICO", //
        "CPC_BD_STATUS_LEAD",
        "CPC_BD_MOTIVOS_RECUSA",
        "CPC_BD_TIPOS_INTERACAO",
        "CPC_BD_UNIDADES_MEDIDA",
        // "CPC_BD_REGIOES",
        // "CPC_BD_MESO_REGIOES",
        // "CPC_BD_MICRO_REGIOES",
    ];

    return _permissoes;
}

function montaMenu(user, permissoes) {
    var oMenuManager = new MenuManager(user, permissoes);

    var oDashboard = new MenuDropdownItems(
        user,
        permissoes,
        "DBC_DASHBOARD",
        "Dashboard",
        "/dashboard",
        "cil-view-quilt"
    );
    //oDashboard.addMenuItem("agenda", 'Agenda', '/dashboard/principal')
    // oDashboard.addMenuItem("CTM_AGENDA", "Agenda", "/customer/agenda");

    var oCustomer = new MenuDropdownItems(
        user,
        permissoes,
        "CTM_CUSTOMER",
        "Customer",
        "/customer",
        "cil-people"
    );
    oCustomer.addMenuItem("CTM_AGENDA", "Agenda", "/customer/agenda");
    oCustomer.addMenuItem(
        "CTM_IDENTIFICACAO_MERCADO",
        "Identificação do Mercado",
        "/customer/identificacao-mercado"
    );
    oCustomer.addMenuItem(
        "CTM_RELATORIO_INTERACAO",
        "Relatórios de Interação",
        "/customer/relatorios-interacao"
    );

    oCustomer.addMenuItem(
        "CPC_BD_TIPOS_INTERACAO",
        "Tipos de Interação",
        "/customer/tipos-interacao"
    );




    // Não tem a página   
    // oCustomer.addMenuItem(
    //     "CTM_IDENTIFICACAO_PRODUTORES",
    //     "Identificação de Produtores",
    //     "/customer/identificacao-produtores"
    // );




    // Página não está pronta    
    // oCustomer.addMenuItem(
    //     "CTM_INDICE_TECNOLOGICO",
    //     "Índice Tecnologico",
    //     "/customer/indice-tecnologico"
    // );



    // Não tem a página
    // oCustomer.addMenuItem(
    //     "CTM_PLANEJAMENTO_VENDA",
    //     "Planejamento de Vendas",
    //     "/customer/planejamento-vendas"
    // );






    var oEstoque = new MenuDropdownItems(
        user,
        permissoes,
        "EST_ESTOQUE",
        "Estoque",
        "/estoque",
        "cil-clipboard"
    );
    oEstoque.addMenuItem("EST_DASHBOARD", "Dashboard", "/estoque/dashboard");
    oEstoque.addMenuItem("EST_RELATORIO", "Relatorio", "/estoque/relatorio");

    var oVendor = new MenuDropdownItems(
        user,
        permissoes,
        "VDR_VENDOR",
        "Vendor",
        "/vendor",
        "cil-cart"
    );
    oVendor.addMenuItem("VDR_NEGOCIACAO", "Negociações", "/vendor/negociacao");
    oVendor.addMenuItem("VDR_PEDIDO", "Pedidos", "/vendor/pedidos");
    oVendor.addMenuItem(
        "VDR_TABELA_PRECOS",
        "Tabela de Preço",
        "/vendor/tabela-preco"
    );
    oVendor.addMenuItem(
        "VDR_ITENS_FATURADOS",
        "Itens Faturados",
        "/vendor/pedido-item-faturado"
    );

    oVendor.addMenuItem(
        "CPC_BD_INDEXADORES",
        "Indexadores",
        "/vendor/indexadores"
    );
    oVendor.addMenuItem(
        "CPC_BD_PRODUTOS",
        "Produtos",
        "/vendor/produtos"
    );
    oVendor.addMenuItem(
        "CPC_BD_FUNIL_VENDAS",
        "Funil de Vendas",
        "/vendor/funil-vendas"
    );
    oVendor.addMenuItem(
        "CPC_BD_STATUS_LEAD",
        "Status de Lead",
        "/vendor/status-lead"
    );
    oVendor.addMenuItem(
        "CPC_BD_MOTIVOS_RECUSA",
        "Motivos de Recusa",
        "/vendor/motivos-recusa"
    );

    // Não tem a página
    var oMarket = new MenuDropdown(
        user,
        permissoes,
        "MKT_MARKET",
        "Market",
        "/market",
        "cil-chart-line"
    );





    // Não tem a página
    var oGeo = new MenuDropdown(
        user,
        permissoes,
        "GEO_GEO",
        "Geo",
        "/geo",
        "cil-location-pin"
    );






    var oCadastros = new MenuDropdownItems(
        user,
        permissoes,
        "CPC_CPANEL",
        "Cadastros",
        "/cadastros",
        "cilPencil"
    );
    oCadastros.addMenuItem(
        "CPC_CD_EMPRESA",
        "Empresa",
        "/cadastros/empresa"
    );

    var oCadastrosProdutor = new MenuDropdownItems(
        user,
        permissoes,
        "CPC_CD_PRODUTOR",
        "Produtor",
        "/cadastros/produtor"
    );

    oCadastros.addMenuDropdown(oCadastrosProdutor);

    oCadastrosProdutor.addMenuItem(
        "CPC_CD_PRODUTORES",
        "Produtores",
        "/cadastros/produtor/produtores"
    );
    oCadastrosProdutor.addMenuItem(
        "CPC_CD_PROPRIEDADES",
        "Propriedades",
        "/cadastros/produtor/propriedades"
    );
    oCadastrosProdutor.addMenuItem(
        "CPC_CD_TALHOES",
        "Talhoes",
        "/cadastros/produtor/talhoes"
    );
    oCadastrosProdutor.addMenuItem(
        "CPC_CD_AREAS_AGRICOLAS",
        "Areas Agricolas",
        "/cadastros/produtor/areas-agricolas"
    );
    oCadastrosProdutor.addMenuItem(
        "CPC_CD_MAQUINAS",
        "Maquinas",
        "/cadastros/produtor/maquinas"
    );

    var oCadastrosUsuarios = new MenuDropdownItems(
        user,
        permissoes,
        "CPC_CD_USUARIOS",
        "Usuarios",
        "/cadastros/usuarios"
    );

    oCadastros.addMenuDropdown(oCadastrosUsuarios);

    oCadastrosUsuarios.addMenuItem(
        "CPC_CD_PERFIL_ACESSO",
        "Perfil de Acesso",
        "/cadastros/usuarios/perfil-acesso"
    );
    oCadastrosUsuarios.addMenuItem(
        "CPC_CD_PERFIL_MENU",
        "Perfil de Menu",
        "/cadastros/usuarios/perfil-menu"
    );
    oCadastrosUsuarios.addMenuItem(
        "CPC_CD_USUARIOS",
        "Usuarios",
        "/cadastros/usuarios/usuarios"
    );
    oCadastrosUsuarios.addMenuItem(
        "CPC_CD_CARTEIRA",
        "Carteira de Clientes",
        "/cadastros/usuarios/carteira"
    );
    // oCadastros.addMenuItem(
    //     "CPC_CD_REDISTRIBUIDORES",
    //     "Redistribuidores",
    //     "/cadastros/redistribuidores"
    // );

    //----------------------
    var oCadastrosRedistribuidores = new MenuDropdownItems(
        user,
        permissoes,
        "CPC_CD_REDISTRIBUIDORES",
        "Redistribuidor",
        "/cadastros/redistribuidor"
    );

    oCadastros.addMenuDropdown(oCadastrosRedistribuidores);

    oCadastrosRedistribuidores.addMenuItem(
        "CPC_CD_REDISTRIBUIDORES",
        "Redistribuidores",
        "/cadastros/redistribuidor/redistribuidores"
    );
    oCadastrosRedistribuidores.addMenuItem(
        "CPC_BD_UNIDADE_REDISTRIBUIDOR",
        "Unidade Redistribuidor",
        "/cadastros/redistribuidor/unidade-redistribuidor"
    );


    //----------------------

    oCadastros.addMenuItem(
        "CPC_BD_CULTURAS_VINCULADAS",
        "Culturas",
        "/cadastros/culturas-vinculadas"
    );
    oCadastros.addMenuItem(
        "CPC_BD_SAFRAS",
        "Safras",
        "/cadastros/safras"
    );

    // oCadastros.addMenuItem(
    //     "CPC_BD_FILIAIS",
    //     "Filiais",
    //     "/cadastros/filiais"
    // );
    oCadastros.addMenuItem(
        "CPC_BD_UNIDADE_ATACADISTA",
        "Unidade Atacadista",
        "/cadastros/unidade-atacadista"
    );
    // oCadastros.addMenuItem(
    //     "CPC_BD_UNIDADE_REDISTRIBUIDOR",
    //     "Unidade Redistribuidor",
    //     "/cadastros/unidade-redistribuidor"
    // );
    oCadastros.addMenuItem(
        "CPC_BD_UNIDADE_DISTRIBUIDOR",
        "Unidade Distribuidor",
        "/cadastros/unidade-distribuidor"
    );

    var oCadastrosAreaDeAtuacao = new MenuDropdownItems(
        user,
        permissoes,
        "CPC_CD_AREA_DE_ATUACAO",
        "Área de atuação",
        "/cadastros/area-de-atuacao"
    );

    oCadastros.addMenuDropdown(oCadastrosAreaDeAtuacao);

    
    oCadastrosAreaDeAtuacao.addMenuItem(
        "CPC_CD_MICRO_REGIOES",
        "Micro Regiões",
        "/cadastros/area-de-atuacao/micro-regioes"
    );
    oCadastrosAreaDeAtuacao.addMenuItem(
        "CPC_CD_MESO_REGIOES",
        "Meso Regiões",
        "/cadastros/area-de-atuacao/meso-regioes"
    );
    oCadastrosAreaDeAtuacao.addMenuItem(
        "CPC_CD_REGIOES",
        "Regiões",
        "/cadastros/area-de-atuacao/regioes"
    );
    
    oCadastros.addMenuItem(
        "CPC_BD_IDENTIFICACAO_MERCADO",
        "Identificação do Mercado",
        "/identificacao-mercado"
    );
    // Não tem a página   
    oCadastros.addMenuItem(
        "CPC_BD_INDICE_TECNOLOGICO",
        "Indice Técnológico",
        "/cadastros/indice-tecnologico"
    );

    oCadastros.addMenuItem(
        "CPC_BD_UNIDADES_MEDIDA",
        "Unidades de Medida",
        "/cadastros/unidades-medida"
    );



    var oBackoffice = new MenuDropdownItems(
        user,
        permissoes,
        "CPE_CPANEL",
        "Back Office",
        "/backoffice",
        "cil-settings"
    );
    //oBackoffice.addMenuItem("backoffice_empresas_antigo", 'Empresas Antigo', '/backoffice/cadastros/empresas-antigo');
    oBackoffice.addMenuItem(
        "CPE_CD_EMPRESAS",
        "Empresas",
        "/backoffice/empresas"
    );

    var oBackofficeSuporte = new MenuDropdownItems(
        user,
        permissoes,
        "SPT_SUPORTE",
        "Suporte",
        "/backoffice/suporte"
    );

    oBackofficeSuporte.addMenuItem(
        "SPT_CF_SEGMENTOS",
        "Segmentos",
        "/backoffice/suporte/segmentos"
    );
    oBackofficeSuporte.addMenuItem(
        "SPT_CF_TIPOS_CLIENTE",
        "Tipos Cliente",
        "/backoffice/suporte/tipos-cliente"
    );
    oBackofficeSuporte.addMenuItem(
        "SPT_CF_TICKETS",
        "Tickets",
        "/backoffice/suporte/tickets"
    );
    oBackofficeSuporte.addMenuItem(
        "SPT_CF_TIPOS_STATUS_TICKET",
        "Tipos Status Tickets",
        "/backoffice/suporte/tipos-status-tickets"
    );
    oBackofficeSuporte.addMenuItem(
        "SPT_CF_TIPOS_STATUS_AGENDAMENTO",
        "Tipos Status Agendamentos",
        "/backoffice/suporte/tipos-status-agendamentos"
    );

    oBackofficeSuporte.addMenuItem(
        "SPT_CF_LOGS",
        "Logs",
        "/backoffice/suporte/logs"
    );
    oBackofficeSuporte.addMenuItem(
        "SPT_CF_ACESSOS",
        "Acessos",
        "/backoffice/suporte/acessos"
    );



    oBackoffice.addMenuDropdown(oBackofficeSuporte);

    var oBackofficeConfiguracoes = new MenuDropdownItems(
        user,
        permissoes,
        "CPE_CF_CONFIGURACOES",
        "Configurações",
        "/backoffice/configuracoes"
    );
    oBackofficeConfiguracoes.addMenuItem(
        "CPE_CF_MENU",
        "Menu",
        "/backoffice/configuracoes/menu"
    );
    oBackofficeConfiguracoes.addMenuItem(
        "CPE_CF_PERFIL_MENU",
        "Perfil Menu",
        "/backoffice/configuracoes/perfil-menu"
    );
    // oBackofficeConfiguracoes.addMenuItem(
    //     "CPE_CF_USUARIO_MASTER_NOVO",
    //     "Usuário Master Novo",
    //     "/backoffice/configuracoes/usuario-master-novo"
    // );

    oBackoffice.addMenuDropdown(oBackofficeConfiguracoes);


    // oBackoffice.addMenuItem(
    //     "CPE_CD_PRODUTOS",
    //     "Usuário Produtos",
    //     "/backoffice/produtos-usuarios"
    // );

  
    oBackoffice.addMenuItem(
        "CPE_CD_USUARIOS",
        "Usuários Clarivi",
        "/backoffice/usuarios"
    );
    oBackoffice.addMenuItem(
        "CPE_CD_PERFIL_ACESSO",
        "Perfil de Acesso",
        "/backoffice/perfil-acesso"
    );
    oBackoffice.addMenuItem(
        "CPE_CD_PERFIL_CONFIG",
        "Configurações de Perfil",
        "/backoffice/perfil-config"
    );


    oBackoffice.addMenuItem(
        "CPE_BD_MUNICIPIOS",
        "Municípios",
        "/backoffice/municipios"
    );   

    oBackoffice.addMenuItem(
        "CPE_BD_CULTURAS",
        "Culturas",
        "/backoffice/culturas"
    );
    oBackoffice.addMenuItem(
        "CPE_BD_INDEXADORES",
        "Indexadores",
        "/backoffice/indexadores"
    );
    oBackoffice.addMenuItem(
        "CPE_BD_FORMAS_PAGAMENTO",
        "Formas de Pagamento",
        "/backoffice/formas-pagamento"
    );
    oBackoffice.addMenuItem(
        "CPE_BD_MODOS_PAGAMENTOS",
        "Modos de Pagamento",
        "/backoffice/modos-pagamento"
    );
    oBackoffice.addMenuItem(
        "CPE_BD_PRODUTOS",
        "Produtos",
        "/backoffice/produtos"
    );



    // Não tem a página
    // oBackoffice.addMenuItem(
    //     "CPE_BD_PACOTE_MANUTENCAO",
    //     "Pacote de Manutenção",
    //     "/backoffice/pacote-manutencacao"
    // );




    oBackoffice.addMenuItem(
        "CPE_BD_MOTIVOS_RECUSA",
        "Motivos de Recusa",
        "/backoffice/motivos-recusa"
    );



    // Não tem a página
    // oBackoffice.addMenuItem(
    //     "CPE_BD_PLANO_ACAO",
    //     "Plano de Ação",
    //     "/backoffice/plano-acao"
    // );



    oBackoffice.addMenuItem(
        "CPE_BD_TIPOS_INTERACAO",
        "Tipos de Interação",
        "/backoffice/tipos-interacao"
    );
    oBackoffice.addMenuItem(
        "CPE_BD_UNIDADES_MEDIDA",
        "Unidades de Medida",
        "/backoffice/unidades-medida"
    );
    oBackoffice.addMenuItem(
        "CPE_BD_UNIDADES_NEGOCIO",
        "Unidades de Negócio",
        "/backoffice/unidades-negocio"
    );
    oBackoffice.addMenuItem(
        "CPE_CF_TIPO_STATUS_ITEM_NEGOCIADO",
        "Status Lead",
        "/backoffice/tipo-status-item-negociado"
    );
    oBackoffice.addMenuItem(
        "CPE_CF_FABRICANTES",
        "Fabricantes",
        "/backoffice/fabricantes"
    );
    oBackoffice.addMenuItem(
        "CPE_CF_FAMILIAS",
        "Familias",
        "/backoffice/familias"
    );
    oBackoffice.addMenuItem(
        "CPE_CF_MARCAS",
        "Marcas",
        "/backoffice/marcas"
    );
    oBackoffice.addMenuItem(
        "CPE_CF_PORTIFOLIO",
        "Portifolio",
        "/backoffice/portifolio"
    );
    oBackoffice.addMenuItem(
        "CPE_CF_CATEGORIAS",
        "Categorias",
        "/backoffice/categorias"
    );
    oBackoffice.addMenuItem(
        "CPE_CF_GRUPOS",
        "Grupos",
        "/backoffice/grupos"
    );


    // Não tem a página
    // oBackoffice.addMenuItem(
    //     "CPE_CF_PRODUTOS",
    //     "Produtos Elipse",
    //     "/backoffice/produtos-elipse"
    // );



    oBackoffice.addMenuItem(
        "CPE_CF_MODULOS",
        "Modulos",
        "/backoffice/modulos"
    );
    oBackoffice.addMenuItem(
        "CPE_CF_FUNCIONALIDADES",
        "Funcionalidades",
        "/backoffice/funcionalidades"
    );
    oBackoffice.addMenuItem(
        "CPE_CF_PERMISSOES",
        "Permissões",
        "/backoffice/permissoes"
    );





    // oBackoffice.addMenuItem(
    //     "SPT_SUPORTE",
    //     "Suporte",
    //     "/backoffice/suporte"
    // );


    // var oSuporte = new MenuDropdown(
    //     user,
    //     permissoes,
    //     "SPT_SUPORTE",
    //     "Suporte",
    //     "/suporte",
    //     "cil-settings"
    // );
    // var oSuporteConfiguracoes = new MenuDropdownItems(
    //     user,
    //     permissoes,
    //     "SPT_CF_CONFIGURACOES",
    //     "Configurações",
    //     "/support/configuracoes"
    // );

    oMenuManager.addMenuDropdown(oDashboard);
    oMenuManager.addMenuDropdown(oGeo);
    oMenuManager.addMenuDropdown(oVendor);
    oMenuManager.addMenuDropdown(oCustomer);
    oMenuManager.addMenuDropdown(oMarket);
    oMenuManager.addMenuDropdown(oEstoque);

    oMenuManager.addMenuDropdown(oCadastros);
    oMenuManager.addMenuDropdown(oBackoffice);

    //oSuporte.addMenuDropdown(oSuporteConfiguracoes);
    //oMenuManager.addMenuDropdown(oSuporte);

    var oMenu = oMenuManager.getMenu();
    return oMenu;
}

const _MenuManager = function (user, menu) {
    // var menuLog = montaMenu(user, permissoes(user, menu));
    // console.log("menu", menuLog);
    return montaMenu(user, permissoes(user, menu));
};

export default _MenuManager;