import Router from "vue-router";
import store from "@/clarivi/store";
import Auth from "@/clarivi/api/auth/auth";

// Containers
const TheContainer = () =>
  import("@/clarivi/containers/TheContainer");

// Views
const Dashboard = () =>
  import("@/clarivi/views/Dashboard");

// View Customer / Agenda
const Customer_Agenda = () =>
  import("@/clarivi/views/subpages/elipse/customer/agenda/agenda");

const Customer_Identificacao_Mercado_Table = () =>
  import("@/clarivi/views/subpages/elipse/customer/identificacao-mercado/table");
const Customer_Identificacao_Mercado_Form = () =>
  import("@/clarivi/views/subpages/elipse/customer/identificacao-mercado/form");

const Customer_Card = () => import("@/clarivi/views/subpages/elipse/customer/index");

const Customer_Relatorios_Interacao_Table = () =>
  import("@/clarivi/views/subpages/elipse/customer/relatorios-interacao/table");
const Customer_Relatorios_Interacao_Detalhes = () =>
  import("@/clarivi/views/subpages/elipse/customer/relatorios-interacao/detalhes");

// const Customer_Indice_Tecnologico_Table = () =>
//   import("@/clarivi/views/subpages/elipse/customer/indice-tecnologico/table");
// const Customer_Indice_Tecnologico_Form = () =>
//   import("@/clarivi/views/subpages/elipse/customer/indice-tecnologico/form");

const Customer_TiposInteracao_Table = () =>
  import("@/clarivi/views/subpages/elipse/customer/tipos-interacao/table");
const Customer_TiposInteracao_Form = () =>
  import("@/clarivi/views/subpages/elipse/customer/tipos-interacao/form");

// View Estoque
const Estoque_Dashboard = () =>
  import("@/clarivi/views/subpages/elipse/estoque/dashboard");
const Estoque_Relatorio = () =>
  import("@/clarivi/views/subpages/elipse/estoque/relatorio");

//View Vendor / Negociações
const Vendor_Negociacao_Table = () =>
  import("@/clarivi/views/subpages/elipse/vendor/negociacao/table");
const Vendor_Negociacao_Form = () =>
  import("@/clarivi/views/subpages/elipse/vendor/negociacao/form");
const Vendor_Negociacao_Detalhes = () =>
  import("@/clarivi/views/subpages/elipse/vendor/negociacao/detalhes");
const Vendor_DetalhesNegociacao_Form = () =>
  import("@/clarivi/views/subpages/elipse/vendor/itens-negociado/form");

//View Vendor / Pedidos

const Vendor_Pedidos_Table = () =>
  import("@/clarivi/views/subpages/elipse/vendor/pedidos/table");
const Vendor_Pedidos_Form = () =>
  import("@/clarivi/views/subpages/elipse/vendor/pedidos/form");
const Vendor_Pedidos_Detalhes = () =>
  import("@/clarivi/views/subpages/elipse/vendor/pedidos/detalhes");
const Vendor_DetalhesPedidos_Form = () =>
  import("@/clarivi/views/subpages/elipse/vendor/itens-pedido/form");

// Tabela de preço
const Vendor_TabelaPreco_Table = () =>
  import("@/clarivi/views/subpages/elipse/vendor/tabela-preco/table");
const Vendor_TabelaPreco_Form = () =>
  import("@/clarivi/views/subpages/elipse/vendor/tabela-preco/form");
const Vendor_TabelaPreco_Detalhes = () =>
  import("@/clarivi/views/subpages/elipse/vendor/tabela-preco/detalhes");

const Vendor_DetalheTabelaPreco_Form = () =>
  import("@/clarivi/views/subpages/elipse/vendor/tabela-preco-produto/form");

const Vendor_Pedido_Item_Faturado_Table = () =>
  import("@/clarivi/views/subpages/elipse/vendor/pedido-item-faturado/table");
const Vendor_Pedido_Item_Faturado_Form = () =>
  import("@/clarivi/views/subpages/elipse/vendor/pedido-item-faturado/form");

const Vendor_Indexadores_Table = () =>

  import("@/clarivi/views/subpages/elipse/vendor/indexador/table");
const Vendor_Indexadores_Form = () =>
  import("@/clarivi/views/subpages/elipse/vendor/indexador/form");

const Vendor_Indexadores_Detalhes = () =>
  import("@/clarivi/views/subpages/elipse/vendor/indexador/detalhes");
const Vendor_DetalhesIndexadores_Form = () =>
  import("@/clarivi/views/subpages/elipse/vendor/indexador-cotacoes/form");

const Vendor_Produtos_Table = () =>
  import("@/clarivi/views/subpages/elipse/vendor/produtos/table");
const Vendor_Produtos_Form = () =>
  import("@/clarivi/views/subpages/elipse/vendor/produtos/form");

const Vendor_Funil_Vendas_Table = () =>
  import("@/clarivi/views/subpages/elipse/vendor/funil-vendas/table");
const Vendor_Funil_Vendas_Form = () =>
  import("@/clarivi/views/subpages/elipse/vendor/funil-vendas/form");

const Vendor_Status_Lead_Table = () =>
  import("@/clarivi/views/subpages/elipse/vendor/status-lead/table");
const Vendor_Status_Lead_Form = () =>
  import("@/clarivi/views/subpages/elipse/vendor/status-lead/form");

const Vendor_Ordem_Table = () =>
  import("@/clarivi/views/subpages/elipse/vendor/ordem/table");
const Vendor_Ordem_Form = () =>
  import("@/clarivi/views/subpages/elipse/vendor/ordem/form");

const Vendor_MotivosRecusa_Table = () =>
  import("@/clarivi/views/subpages/elipse/vendor/motivos-recusa/table");
const Vendor_MotivosRecusa_Form = () =>
  import("@/clarivi/views/subpages/elipse/vendor/motivos-recusa/form");

// Cadastros base de dados


const Cadastros_Composicao_Mercado_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/composicao-mercado/table");
const Cadastros_Composicao_Mercado_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/composicao-mercado/form");
const Cadastros_UnidadesMedida_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/unidades-medida/table");
const Cadastros_UnidadesMedida_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/unidades-medida/form");

const Cadastros_Filiais_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/filiais/table");
const Cadastros_Filiais_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/filiais/form"); 

const Cadastros_Regioes_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/area-de-atuacao/regioes/table");
const Cadastros_Regioes_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/area-de-atuacao/regioes/form");

const Cadastros_MesoRegioes_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/area-de-atuacao/meso-regioes/table");
const Cadastros_MesoRegioes_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/area-de-atuacao/meso-regioes/form");

const Cadastros_MicroRegioes_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/area-de-atuacao/micro-regioes/table");
const Cadastros_MicroRegioes_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/area-de-atuacao/micro-regioes/form");

  const Cadastros_Unidade_Atacadista_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/unidade-atacadista/table");
const Cadastros_Unidade_Atacadista_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/unidade-atacadista/form");
// const Cadastros_Unidade_Redistribuidor_Table = () =>
//   import("@/clarivi/views/subpages/elipse/cadastros/unidade-redistribuidor/table");
// const Cadastros_Unidade_Redistribuidor_Form = () =>
//   import("@/clarivi/views/subpages/elipse/cadastros/unidade-redistribuidor/form");
const Cadastros_Unidade_Distribuidor_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/unidade-distribuidor/table");
const Cadastros_Unidade_Distribuidor_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/unidade-distribuidor/form");



const Cadastros_Indice_Tecnologico_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/indice-tecnologico/table");
const Cadastros_Indice_Tecnologico_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/indice-tecnologico/form");

const Cadastros_Culturas_Vinculadas_Table = () =>
  import(
    "@/clarivi/views/subpages/elipse/cadastros/culturas-vinculadas/table"
  );
const Cadastros_Culturas_Vinculadas_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/culturas-vinculadas/form");

  const Cadastros_Safras_Table = () =>
  import(
    "@/clarivi/views/subpages/elipse/cadastros/safras/table"
  );
const Cadastros_Safras_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/safras/form");


const Cadastros_Identificacao_Mercado_Table = () =>
  import(
    "@/clarivi/views/subpages/elipse/cadastros/identificacao-mercado/table"
  );
const Cadastros_Identificacao_Mercado_Form = () =>
  import(
    "@/clarivi/views/subpages/elipse/cadastros/identificacao-mercado/form"
  );

// cadastros

const Cadastros_Empresa_Detalhes = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/empresa/detalhes"); 
const Cadastros_Talhoes_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/produtor/talhoes/table");
const Cadastros_Talhoes_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/produtor/talhoes/form");


const Cadastros_Areas_Agricolas_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/produtor/areas-agricolas/table");
const Cadastros_Areas_Agricolas_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/produtor/areas-agricolas/form");

const Cadastros_Produtores_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/produtor/produtores/table");
const Cadastros_Produtores_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/produtor/produtores/form");

const Cadastros_Propriedades_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/produtor/propriedades/table");
const Cadastros_Propriedades_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/produtor/propriedades/form");

const Cadastros_Maquinas_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/produtor/maquinas/table");
const Cadastros_Maquinas_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/produtor/maquinas/form");

// const Cadastros_Redistribuidores_Table = () =>
//   import("@/clarivi/views/subpages/elipse/cadastros/redistribuidores/table");
// const Cadastros_Redistribuidores_Form = () =>
//   import("@/clarivi/views/subpages/elipse/cadastros/redistribuidores/form");

const Cadastros_Usuarios_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/usuario/usuarios/table");
const Cadastros_Usuarios_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/usuario/usuarios/form");

const Cadastros_Carteira_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/usuario/carteira/table");
const Cadastros_Carteira_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/usuario/carteira/form");
const Cadastros_Carteira_Detalhes = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/usuario/carteira/detalhes");

const Cadastros_DetalhesCarteira_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/usuario/clientes-carteira/form");

const Cadastros_PerfilAcesso_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/usuario/perfil-acesso/table");
const Cadastros_PerfilAcesso_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/usuario/perfil-acesso/form");

  const Cadastros_PerfilMenu_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/usuario/perfil-menu/table");
const Cadastros_PerfilMenu_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/usuario/perfil-menu/form");

const Cadastros_Redistribuidores_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/redistribuidor/redistribuidores/table");
const Cadastros_Redistribuidores_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/redistribuidor/redistribuidores/form");
const Cadastros_Unidade_Redistribuidor_Table = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/redistribuidor/unidade-redistribuidor/table");
const Cadastros_Unidade_Redistribuidor_Form = () =>
  import("@/clarivi/views/subpages/elipse/cadastros/redistribuidor/unidade-redistribuidor/form");



// View Backoffice / Cadastros
const Backoffice_Dashboard = () =>
  import("@/clarivi/views/subpages/backoffice/dashboards/Dashboard");



// View Backoffice / Cadastros


const Backoffice_Empresas_Antigo_Table = () =>
  import("@/clarivi/views/subpages/backoffice/empresas-antigo/table");
const Backoffice_Empresas_Antigo_Form = () =>
  import("@/clarivi/views/subpages/backoffice/empresas-antigo/form");

const Backoffice_Empresas_Table = () =>
  import("@/clarivi/views/subpages/backoffice/empresas/table");
const Backoffice_Empresas_Form = () =>
  import("@/clarivi/views/subpages/backoffice/empresas/form");


const Backoffice_ProdutosUsuarios_Table = () =>
  import("@/clarivi/views/subpages/backoffice/produtos-usuarios/table");
const Backoffice_ProdutosUsuarios_Form = () =>
  import("@/clarivi/views/subpages/backoffice/produtos-usuarios/form");




const Backoffice_UsuarioMaster_Table = () =>
  import("@/clarivi/views/subpages/backoffice/configuracoes/usuario-master/table");
const Backoffice_UsuarioMaster_Form = () =>
  import("@/clarivi/views/subpages/backoffice/configuracoes/usuario-master/form");
const Backoffice_UsuarioMaster_Config = () =>
  import("@/clarivi/views/subpages/backoffice/configuracoes/usuario-master/table");


const Backoffice_Usuarios_Table = () =>
  import("@/clarivi/views/subpages/backoffice/usuarios/table");
const Backoffice_Usuarios_Form = () =>
  import("@/clarivi/views/subpages/backoffice/usuarios/form");

const Backoffice_PerfilAcesso_Table = () =>
  import("@/clarivi/views/subpages/backoffice/perfil-acesso/table");
const Backoffice_PerfilAcesso_Form = () =>
  import("@/clarivi/views/subpages/backoffice/perfil-acesso/form");
const Backoffice_PerfilAcesso_Config = () =>
  import("@/clarivi/views/subpages/backoffice/perfil-acesso/config");

const Backoffice_PerfilConfig_Table = () =>
  import("@/clarivi/views/subpages/backoffice/perfil-config/table");
const Backoffice_PerfilConfig_Form = () =>
  import("@/clarivi/views/subpages/backoffice/perfil-config/form");

// View Backoffice / Base Dados

const Backoffice_Produtos_Table = () =>
  import("@/clarivi/views/subpages/backoffice/produtos/table");
const Backoffice_Produtos_Form = () =>
  import("@/clarivi/views/subpages/backoffice/produtos/form");

const Backoffice_Municipios_Table = () =>
  import("@/clarivi/views/subpages/backoffice/municipios/table");
const Backoffice_Municipios_Form = () =>
  import("@/clarivi/views/subpages/backoffice/municipios/form");

const Backoffice_Culturas_Table = () =>
  import("@/clarivi/views/subpages/backoffice/culturas/table");
const Backoffice_Culturas_Form = () =>
  import("@/clarivi/views/subpages/backoffice/culturas/form");

const Backoffice_Indexadores_Table = () =>
  import("@/clarivi/views/subpages/backoffice/indexador/table");
const Backoffice_Indexadores_Form = () =>
  import("@/clarivi/views/subpages/backoffice/indexador/form");

const Backoffice_MotivosRecusa_Table = () =>
  import("@/clarivi/views/subpages/backoffice/motivos-recusa/table");
const Backoffice_MotivosRecusa_Form = () =>
  import("@/clarivi/views/subpages/backoffice/motivos-recusa/form");

const Backoffice_TiposInteracao_Table = () =>
  import("@/clarivi/views/subpages/backoffice/tipos-interacao/table");
const Backoffice_TiposInteracao_Form = () =>
  import("@/clarivi/views/subpages/backoffice/tipos-interacao/form");

const Backoffice_UnidadesMedida_Table = () =>
  import("@/clarivi/views/subpages/backoffice/unidades-medida/table");
const Backoffice_UnidadesMedida_Form = () =>
  import("@/clarivi/views/subpages/backoffice/unidades-medida/form");

const Backoffice_UnidadesNegocio_Table = () =>
  import("@/clarivi/views/subpages/backoffice/unidades-negocio/table");
const Backoffice_UnidadesNegocio_Form = () =>
  import("@/clarivi/views/subpages/backoffice/unidades-negocio/form");

const Backoffice_FormasPagamento_Table = () =>
  import("@/clarivi/views/subpages/backoffice/formas-pagamento/table");
const Backoffice_FormasPagamento_Form = () =>
  import("@/clarivi/views/subpages/backoffice/formas-pagamento/form");

const Backoffice_ModosPagamento_Table = () =>
  import("@/clarivi/views/subpages/backoffice/modos-pagamento/table");
const Backoffice_ModosPagamento_Form = () =>
  import("@/clarivi/views/subpages/backoffice/modos-pagamento/form");

// View Backoffice / Configurações
const Backoffice_TipoStatusItemNegociado_Table = () =>
  import(
    "@/clarivi/views/subpages/backoffice/tipo-status-item-negociado/table"
  );
const Backoffice_TipoStatusItemNegociado_Form = () =>
  import(
    "@/clarivi/views/subpages/backoffice/tipo-status-item-negociado/form"
  );

// const Backoffice_Regioes_Table = () =>
//   import("@/clarivi/views/subpages/backoffice/regioes/table");
// const Backoffice_Regioes_Form = () =>
//   import("@/clarivi/views/subpages/backoffice/regioes/form");

// const Backoffice_MesoRegioes_Table = () =>
//   import("@/clarivi/views/subpages/backoffice/meso-regioes/table");
// const Backoffice_MesoRegioes_Form = () =>
//   import("@/clarivi/views/subpages/backoffice/meso-regioes/form");

// const Backoffice_MicroRegioes_Table = () =>
//   import("@/clarivi/views/subpages/backoffice/micro-regioes/table");
// const Backoffice_MicroRegioes_Form = () =>
//   import("@/clarivi/views/subpages/backoffice/micro-regioes/form");

const Backoffice_Fabricantes_Table = () =>
  import("@/clarivi/views/subpages/backoffice/fabricantes/table");
const Backoffice_Fabricantes_Form = () =>
  import("@/clarivi/views/subpages/backoffice/fabricantes/form");

const Backoffice_Familias_Table = () =>
  import("@/clarivi/views/subpages/backoffice/familias/table");
const Backoffice_Familias_Form = () =>
  import("@/clarivi/views/subpages/backoffice/familias/form");

const Backoffice_Portifolio_Table = () =>
  import("@/clarivi/views/subpages/backoffice/portifolio/table");
const Backoffice_Portifolio_Form = () =>
  import("@/clarivi/views/subpages/backoffice/portifolio/form");

const Backoffice_Marcas_Table = () =>
  import("@/clarivi/views/subpages/backoffice/marcas/table");
const Backoffice_Marcas_Form = () =>
  import("@/clarivi/views/subpages/backoffice/marcas/form");

const Backoffice_Categorias_Table = () =>
  import("@/clarivi/views/subpages/backoffice/categorias/table");
const Backoffice_Categorias_Form = () =>
  import("@/clarivi/views/subpages/backoffice/categorias/form");

const Backoffice_Grupos_Table = () =>
  import("@/clarivi/views/subpages/backoffice/grupos/table");
const Backoffice_Grupos_Form = () =>
  import("@/clarivi/views/subpages/backoffice/grupos/form");

const Backoffice_Produtos_Elipse_Table = () =>
  import(
    "@/clarivi/views/subpages/backoffice/produtos-elipse/table"
  );
const Backoffice_Produtos_Elipse_Form = () =>
  import("@/clarivi/views/subpages/backoffice/produtos-elipse/form");

const Backoffice_Modulos_Table = () =>
  import("@/clarivi/views/subpages/backoffice/modulos/table");
const Backoffice_Modulos_Form = () =>
  import("@/clarivi/views/subpages/backoffice/modulos/form");

const Backoffice_Funcionalidades_Table = () =>
  import(
    "@/clarivi/views/subpages/backoffice/funcionalidades/table"
  );
const Backoffice_Funcionalidades_Form = () =>
  import("@/clarivi/views/subpages/backoffice/funcionalidades/form");

const Backoffice_Permissoes_Table = () =>
  import("@/clarivi/views/subpages/backoffice/permissoes/table");
const Backoffice_Permissoes_Form = () =>
  import("@/clarivi/views/subpages/backoffice/permissoes/form");



// View Support / Configuracoes
const Backoffice_Segmentos_Table = () =>
  import("@/clarivi/views/subpages/backoffice/suporte/segmentos/table");
const Backoffice_Segmentos_Form = () =>
  import("@/clarivi/views/subpages/backoffice/suporte/segmentos/form");

const Backoffice_TiposCliente_Table = () =>
  import("@/clarivi/views/subpages/backoffice/suporte/tipos-cliente/table");
const Backoffice_TiposCliente_Form = () =>
  import("@/clarivi/views/subpages/backoffice/suporte/tipos-cliente/form");

const Backoffice_Tickets_Table = () =>
  import("@/clarivi/views/subpages/backoffice/suporte/tickets/table");
const Backoffice_Tickets_Form = () =>
  import("@/clarivi/views/subpages/backoffice/suporte/tickets/form");

const Backoffice_Tipos_Status_Tickets_Table = () =>
  import(
    "@/clarivi/views/subpages/backoffice/suporte/tipos-status-tickets/table"
  );
const Backoffice_Tipos_Status_Tickets_Form = () =>
  import(
    "@/clarivi/views/subpages/backoffice/suporte/tipos-status-tickets/form"
  );

const Backoffice_Tipos_Status_Agendamentos_Table = () =>
  import(
    "@/clarivi/views/subpages/backoffice/suporte/tipos-status-agendamentos/table"
  );
const Backoffice_Tipos_Status_Agendamentos_Form = () =>
  import(
    "@/clarivi/views/subpages/backoffice/suporte/tipos-status-agendamentos/form"
  );

const Backoffice_Logs_Table = () =>
  import("@/clarivi/views/subpages/backoffice/suporte/logs/table");
const Backoffice_Logs_Form = () =>
  import("@/clarivi/views/subpages/backoffice/suporte/logs/form");

const Backoffice_Acessos_Table = () =>
  import("@/clarivi/views/subpages/backoffice/suporte/acessos/table");
const Backoffice_Acessos_Form = () =>
  import("@/clarivi/views/subpages/backoffice/suporte/acessos/form");

 // Configuracoes

const Backoffice_Menu_Table = () =>
  import("@/clarivi/views/subpages/backoffice/configuracoes/menu/table");
const Backoffice_Menu_Form = () =>
  import("@/clarivi/views/subpages/backoffice/configuracoes/menu/form");

const Backoffice_Perfil_Menu_Table = () =>
  import("@/clarivi/views/subpages/backoffice/configuracoes/perfil-menu/table");
const Backoffice_Perfil_Menu_Form = () =>
  import("@/clarivi/views/subpages/backoffice/configuracoes/perfil-menu/form");















const Page404_Internal = () =>
  import("@/clarivi/views/subpages/Page404");

// Views - Pages
const Page404 = () =>
  import("@/clarivi/views/pages/Page404");
const Page500 = () =>
  import("@/clarivi/views/pages/Page500");
const Login = () =>
  import("@/clarivi/views/pages/Login");
const Logout = () =>
  import("@/clarivi/views/pages/Logout");
const Recuperar = () =>
  import("@/clarivi/views/pages/Recuperar");
const MudarSenha = () =>
  import("@/clarivi/views/pages/MudarSenha");
const NovaSenha = () =>
  import("@/clarivi/views/pages/NovaSenha");

const router = new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  //mode: 'history',
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [{
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/recuperar",
    name: "Recuperar",
    component: Recuperar,
  },
  {
    path: "/nova-senha/:token",
    name: "NovaSenha",
    component: NovaSenha,
  },
  {
    path: "",
    name: "Home",
    redirect: "dashboard",
    meta: { auth: true, label: "Home" },
    component: TheContainer,
    children: [{
      path: "dashboard",
      name: "Dashboard",
      meta: { label: "Dashboard" },
      component: Dashboard
    },

    {
      path: "customer",
      name: "Customer",
      redirect: "customer/card",
      meta: { label: "Customer" },
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "card",
          name: "Card",
          meta: { label: "Card" },
          component: Customer_Card,
        },
        {
          path: "agenda",
          name: "Agenda",
          meta: { label: "Agenda" },
          component: Customer_Agenda,
        },
        {
          path: "identificacao-mercado",
          name: "customer-identificacao-mercado_table",
          meta: { label: "Identificação de Mercado" },
          component: Customer_Identificacao_Mercado_Table,
        },
        {
          path: "identificacao-mercado/:action/:id?",
          name: "customer-identificacao-mercado_form",
          meta: { label: "Identificação de Mercado" },
          component: Customer_Identificacao_Mercado_Form,
        },
        /*
              {
                path: "identificacao-produtores",
                name: "customer-identificacao-produtores_mapa",
                meta: { label: "Identificação de Produtores" },
                component: Customer_Identificacao_Produtores_Mapa,
              },
        */
        {
          path: "relatorios-interacao",
          name: "customer-relatorios-interacao_table",
          meta: { label: "Relatórios de Interação" },
          component: Customer_Relatorios_Interacao_Table,
        },
        {
          path: "relatorios-interacao/:action/:id?",
          name: "customer-relatorios-interacao_detalhes",
          meta: { label: "Relatórios de Interação" },
          component: Customer_Relatorios_Interacao_Detalhes,
        },
        // {
        //   path: "indice-tecnologico",
        //   name: "indice-tecnologico-table",
        //   meta: { label: "Índice Tecnológico" },
        //   component: Customer_Indice_Tecnologico_Table,
        // },
        // {
        //   path: "indice-tecnologico/:action/:id?",
        //   name: "indice-tecnologico-form",
        //   meta: { label: "Índice Tecnológico" },
        //   component: Customer_Indice_Tecnologico_Form,
        // },
        {
          path: "tipos-interacao",
          name: "cadastros-tipos-interacao-table",
          meta: { label: "Tipos de Interação" },
          component: Customer_TiposInteracao_Table,
        },
        {
          path: "tipos-interacao/:action/:id?",
          name: "cadastros-tipos-interacao-form",
          meta: { label: "Tipos de Interação" },
          component: Customer_TiposInteracao_Form,
        },
      ],
    },


    {
      path: "estoque",
      name: "Estoque",
      meta: { label: "Estoque" },
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "dashboard",
          name: "estoque_dashboard",
          meta: { label: "Dashboard" },
          component: Estoque_Dashboard,
        },
        {
          path: "relatorio",
          name: "estoque_relatorio",
          meta: { label: "Relatório" },
          component: Estoque_Relatorio,
        },

      ],
    },
    {
      path: "vendor",
      name: "Vendor",
      meta: { label: "Vendor" },
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "negociacao/detalhes/:negociacaoId",
          name: "detalhes_negociacao_list",
          meta: { label: "Detalhes da Negociacão" },
          component: Vendor_Negociacao_Detalhes,
        },
        {
          path: "negociacao/detalhes/:negociacaoId/:action/:id?",
          name: "detalhes_negociacao_form",
          meta: { label: "Negociação" },
          component: Vendor_DetalhesNegociacao_Form,
        },
        {
          path: "negociacao",
          name: "vendor-negociacao-table",
          meta: { label: "Negociações" },
          component: Vendor_Negociacao_Table,
        },
        {
          path: "negociacao/:action/:id?",
          name: "vendor-negociacao-form",
          meta: { label: "Negociações" },
          component: Vendor_Negociacao_Form,
        },

        //----

        {
          path: "pedidos/detalhes/:pedidoId",
          name: "detalhes_pedidos_list",
          meta: { label: "Detalhes do Pedido" },
          component: Vendor_Pedidos_Detalhes,
        },
        {
          path: "pedidos/detalhes/:pedidoId/:action/:id?",
          name: "detalhes_pedidos_form",
          meta: { label: "Pedido" },
          component: Vendor_DetalhesPedidos_Form,
        },
        {
          path: "pedidos",
          name: "vendor-pedidos-table",
          meta: { label: "Pedidos" },
          component: Vendor_Pedidos_Table,
        },
        {
          path: "pedidos/:action/:id?",
          name: "vendor-pedidos-form",
          meta: { label: "Pedidos" },
          component: Vendor_Pedidos_Form,
        },

        //----
        {
          path: "tabela-preco/:tabelaPrecoId/detalhes",
          name: "detalhes_tabela-preco_list",
          meta: { label: "Detalhes da Tabela de Preço" },
          component: Vendor_TabelaPreco_Detalhes,
        },
        {
          path: "tabela-preco/:tabelaPrecoId/detalhes/:action/:id?",
          name: "detalhes_tabela-preco_form",
          meta: { label: "Tabela de Preço" },
          component: Vendor_DetalheTabelaPreco_Form,
        },
        {
          path: "tabela-preco",
          name: "tabela-preco_table",
          meta: { label: "Tabela de Preço" },
          component: Vendor_TabelaPreco_Table,
        },
        {
          path: "tabela-preco/:action/:id?",
          name: "tabela-preco_form",
          meta: { label: "Tabela de Preço" },
          component: Vendor_TabelaPreco_Form,
        },

        {
          path: "pedido-item-faturado",
          name: "pedido-item-faturado_table",
          meta: { label: "Itens Faturados" },
          component: Vendor_Pedido_Item_Faturado_Table,
        },
        {
          path: "pedido-item-faturado/:action/:id?",
          name: "pedido-item-faturado_form",
          meta: { label: "Itens Faturados" },
          component: Vendor_Pedido_Item_Faturado_Form,
        },
         {
          path: "indexadores/:indexadorId/detalhes",
          name: "detalhes_indexador_list",
          meta: { label: "Detalhes do Indexador" },
          component: Vendor_Indexadores_Detalhes,
        },
        {
          path: "indexador/:indexadorId/detalhes/:action/:id?",
          name: "detalhes_indexador_form",
          meta: { label: "Detalhes do Indexador" },
          component: Vendor_DetalhesIndexadores_Form,
        },
        {
          path: "indexadores",
          name: "indexador_table",
          meta: { label: "Indexadores" },
          component: Vendor_Indexadores_Table,
        }, 
        {
            path: "indexadores/:action/:id?",
            name: "indexador_form",
            meta: { label: "Indexadores" },
            component: Vendor_Indexadores_Form,
          },
          {
            path: "produtos",
            name: "vendor-produtos-table",
            meta: { label: "Produtos" },
            component: Vendor_Produtos_Table,
          },
          {
            path: "produtos/:action/:id?",
            name: "vendor-dados-produtos-form",
            meta: { label: "Produtos" },
            component: Vendor_Produtos_Form,
          },
          {
          path: "funil-vendas",
          name: "vendor-funil-vendas-table",
          meta: { label: "Funil de Vendas" },
          component: Vendor_Funil_Vendas_Table,
        },
        {
          path: "funil-vendas/:action/:id?",
          name: "vendor-funil-vendas-form",
          meta: { label: "Funil de Vendas" },
          component: Vendor_Funil_Vendas_Form,
        },
        {
          path: "status-lead",
          name: "vendor-status-lead-table",
          meta: { label: "Status de Lead" },
          component: Vendor_Status_Lead_Table,
        },
        {
          path: "status-lead/:action/:id?",
          name: "vendor-status-lead-form",
          meta: { label: "Status de Lead" },
          component: Vendor_Status_Lead_Form,
        },
        {
          path: "ordem",
          name: "vendor-ordem-table",
          meta: { label: "Ordem" },
          component: Vendor_Ordem_Table,
        },
        {
          path: "ordem/:action/:id?",
          name: "vendor-ordem-form",
          meta: { label: "Status de Lead" },
          component: Vendor_Ordem_Form,
        },        
        {
          path: "motivos-recusa",
          name: "vendor-motivos-recusa-table",
          meta: { label: "Motivos de Recusa" },
          component: Vendor_MotivosRecusa_Table,
        },
        {
          path: "motivos-recusa/:action/:id?",
          name: "vendor-motivos-recusa-form",
          meta: { label: "Motivos de Recusa" },
          component: Vendor_MotivosRecusa_Form,
        },,
      ],
    },

    {
      path: "cadastros",
      name: "Cadastros",
      meta: { label: "Cadastros" },
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [

        {
          path: "usuarios",
          name: "Usuarios",
          meta: { label: "Usuarios" },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            
        {
          path: "perfil-acesso",
          name: "cadastros-perfil-acesso-table",
          meta: { label: "Perfil Acesso" },
          component: Cadastros_PerfilAcesso_Table,
        },
        {
          path: "perfil-acesso/:action/:id?",
          name: "cadastros-perfil-acesso-form",
          meta: { label: "Perfil Acesso" },
          component: Cadastros_PerfilAcesso_Form,
        },
        {
          path: "perfil-menu",
          name: "cadastros-perfil-menu-table",
          meta: { label: "Perfil Menu" },
          component: Cadastros_PerfilMenu_Table,
        },
        {
          path: "perfil-menu/:action/:id?",
          name: "cadastros-perfil-menu-form",
          meta: { label: "Perfil Menu" },
          component: Cadastros_PerfilMenu_Form,
        },
        {
          path: "usuarios",
          name: "cadastros-usuarios-table",
          meta: { label: "Usuarios" },
          component: Cadastros_Usuarios_Table,
        },
        {
          path: "usuarios/:action/:id?",
          name: "cadastros-usuarios-form",
          meta: { label: "Usuarios" },
          component: Cadastros_Usuarios_Form,
        },
        {
          path: "carteira",
          name: "cadastros-carteira-table",
          meta: { label: "Carteiras" },
          component: Cadastros_Carteira_Table,
        },
        {
          path: "carteira/detalhes/:carteiraId?",
          name: "cadastros-carteira-detalhes",
          meta: { label: "Carteiras" },
          component: Cadastros_Carteira_Detalhes,
        },
        {
          path: "carteira/:action/:id?",
          name: "cadastros-carteira-form",
          meta: { label: "Carteiras" },
          component: Cadastros_Carteira_Form,
        },
        {
          path: "carteira/detalhe/:action/:id?",
          name: "cadastros-carteira-detalhe-form",
          meta: { label: "Carteiras" },
          component: Cadastros_DetalhesCarteira_Form,
        },     

      ],
    },

    {
      path: "redistribuidor",
      name: "Redistribuidor",
      meta: { label: "Redistribuidor" },
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        
        {
          path: "redistribuidores",
          name: "cadastro-redistribuidores-table",
          meta: { label: "Redistribuidores" },
          component: Cadastros_Redistribuidores_Table,
        },
        {
          path: "redistribuidores/:action/:id?",
          name: "cadastro-redistribuidores-form",
          meta: { label: "Redistribuidores" },
          component: Cadastros_Redistribuidores_Form,
        },
        {
          path: "unidade-redistribuidor",
          name: "cadastros-unidade-redistribuidor-table",
          meta: { label: "Unidade Redistribuidor" },
          component: Cadastros_Unidade_Redistribuidor_Table,
        },
        {
          path: "unidade-redistribuidor/:action/:id?",
          name: "cadastros-unidade-redistribuidor-form",
          meta: { label: "Unidade Redistribuidor" },
          component: Cadastros_Unidade_Redistribuidor_Form,
        },  
  ],
},

        // base de dados

        // {
        //   path: "funil-vendas",
        //   name: "cadastros-funil-vendas-table",
        //   meta: { label: "Funil de Vendas" },
        //   component: Cadastros_Funil_Vendas_Table,
        // },
        // {
        //   path: "funil-vendas/:action/:id?",
        //   name: "cadastros-funil-vendas-form",
        //   meta: { label: "Funil de Vendas" },
        //   component: Cadastros_Funil_Vendas_Form,
        // },
        {
          path: "composicao-mercado",
          name: "cadastros-composicao-mercado-table",
          meta: { label: "Composição de Mercado" },
          component: Cadastros_Composicao_Mercado_Table,
        },
        {
          path: "composicao-mercado/:action/:id?",
          name: "cadastros-composicao-mercados-form",
          meta: { label: "Composição de Mercado" },
          component: Cadastros_Composicao_Mercado_Form,
        },        
        {
          path: "identificacao-mercado",
          name: "cadastros-identificacao-mercado-table",
          meta: { label: "Identificação de Mercado" },
          component: Cadastros_Identificacao_Mercado_Table,
        },
        {
          path: "identificacao-mercado/:action/:id?",
          name: "cadastros-identificacao-mercado-form",
          meta: { label: "Identificação de Mercado" },
          component: Cadastros_Identificacao_Mercado_Form,
        },
        {
          path: "filiais",
          name: "cadastros-filiais-table",
          meta: { label: "Filiais" },
          component: Cadastros_Filiais_Table,
        },
        {
          path: "filiais/:action/:id?",
          name: "cadastros-filiais-form",
          meta: { label: "Filiais" },
          component: Cadastros_Filiais_Form,
        },

        {
          path: "unidade-atacadista",
          name: "cadastros-unidade-atacadista-table",
          meta: { label: "Unidade Atacadista" },
          component: Cadastros_Unidade_Atacadista_Table,
        },
        {
          path: "unidade-atacadista/:action/:id?",
          name: "cadastros-unidade-atacadista-form",
          meta: { label: "Unidade Atacadista" },
          component: Cadastros_Unidade_Atacadista_Form,
        },
        // {
        //   path: "unidade-redistribuidor",
        //   name: "cadastros-unidade-redistribuidor-table",
        //   meta: { label: "Unidade Redistribuidor" },
        //   component: Cadastros_Unidade_Redistribuidor_Table,
        // },
        // {
        //   path: "unidade-redistribuidor/:action/:id?",
        //   name: "cadastros-unidade-redistribuidor-form",
        //   meta: { label: "Unidade Redistribuidor" },
        //   component: Cadastros_Unidade_Redistribuidor_Form,
        // },
        {
          path: "unidade-distribuidor",
          name: "cadastros-unidade-distribuidor-table",
          meta: { label: "Unidade Distribuidor" },
          component: Cadastros_Unidade_Distribuidor_Table,
        },
        {
          path: "unidade-distribuidor/:action/:id?",
          name: "cadastros-unidade-distribuidor-form",
          meta: { label: "Unidade Distribuidor" },
          component: Cadastros_Unidade_Distribuidor_Form,
        },
       
        {
          path: "area-de-atuacao",
          meta: { label: "Area de Atuacao" },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "regioes",
              name: "cadastros-regioes-table",
              meta: { label: "Regiões" },
              component: Cadastros_Regioes_Table,
            },
            {
              path: "regioes/:action/:id?",
              name: "cadastros-regioes-form",
              meta: { label: "Regiões" },
              component: Cadastros_Regioes_Form,
            },
    
            {
              path: "meso-regioes",
              name: "cadastros-meso-regioes-table",
              meta: { label: "Meso Regiões" },
              component: Cadastros_MesoRegioes_Table,
            },
    
            {
              path: "meso-regioes/:action/:id?",
              name: "cadastros-meso-regioes-form",
              meta: { label: "Meso Regiões" },
              component: Cadastros_MesoRegioes_Form,
            },
    
            {
              path: "micro-regioes",
              name: "cadastros-micro-regioes-table",
              meta: { label: "Micro Regiões" },
              component: Cadastros_MicroRegioes_Table,
            },
            {
              path: "micro-regioes/:action/:id?",
              name: "cadastros-micro-regioes-form",
              meta: { label: "Micro Regiões" },
              component: Cadastros_MicroRegioes_Form,
            },
          ]
        },      
        
        {
          path: "indice-tecnologico",
          name: "indice-tecnologico-table",
          meta: { label: "Índice Tecnológico" },
          component: Cadastros_Indice_Tecnologico_Table,
        },
        {
          path: "indice-tecnologico/:action/:id?",
          name: "indice-tecnologico-form",
          meta: { label: "Índice Tecnológico" },
          component: Cadastros_Indice_Tecnologico_Form,
        },



        // {
        //   path: 'regioes',
        //   name: 'cpanel-base-dados-regioes-table',
        //   meta: { label: 'Municípios' },
        //   component: Backoffice_Regioes_Table
        // },
        // {
        //   path: 'regioes/:action/:id?',
        //   name: 'cpanel-base-dados-regioes-form',
        //   meta: { label: 'Municípios' },
        //   component: Backoffice_Regioes_Form
        // },

        {
          path: "culturas-vinculadas",
          name: "cadastros-culturas-vinculadas-table",
          meta: { label: "Culturas" },
          component: Cadastros_Culturas_Vinculadas_Table,
        },
        {
          path: "culturas-vinculadas/:action/:id?",
          name: "cadastros-culturas-vinculadas-form",
          meta: { label: "Culturas" },
          component: Cadastros_Culturas_Vinculadas_Form,
        },
        {
          path: "safras",
          name: "cadastros-safras-table",
          meta: { label: "Safras" },
          component: Cadastros_Safras_Table,
        },
        {
          path: "safras/:action/:id?",
          name: "cadastros-safras-form",
          meta: { label: "Safras" },
          component: Cadastros_Safras_Form,
        },
        {
          path: "unidades-medida",
          name: "cadastros-unidades-medida-table",
          meta: { label: "Unidades de Medida" },
          component: Cadastros_UnidadesMedida_Table,
        },
        {
          path: "unidades-medida/:action/:id?",
          name: "cadastros-unidades-medida-form",
          meta: { label: "Unidades de Medida" },
          component: Cadastros_UnidadesMedida_Form,
        },
        {
          path: "empresa",
          name: "cadastros-empresas-detalhes",
          meta: { label: "Empresas" },
          component: Cadastros_Empresa_Detalhes,
        },
        {
          path: "produtor",
          meta: { label: "Produtor" },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "talhoes",
              name: "cadastros-talhoes-table",
              meta: { label: "Talhões" },
              component: Cadastros_Talhoes_Table,
            },
            {
              path: "talhoes/:action/:id?",
              name: "cadastros-talhoes-form",
              meta: { label: "Talhões" },
              component: Cadastros_Talhoes_Form,
            },

            {
              path: "areas-agricolas",
              name: "cadastros-areas-agricolas-table",
              meta: { label: "Áreas Agrícolas" },
              component: Cadastros_Areas_Agricolas_Table,
            },
            {
              path: "areas-agricolas/:action/:id?",
              name: "cadastros-areas-agricolas-form",
              meta: { label: "Áreas Agrícolas" },
              component: Cadastros_Areas_Agricolas_Form,
            },
    
    
            {
              path: "produtores",
              name: "cadastros-produtores-table",
              meta: { label: "Produtores" },
              component: Cadastros_Produtores_Table,
            },
            {
              path: "produtores/:action/:id?",
              name: "cadastros-produtores-form",
              meta: { label: "Produtores" },
              component: Cadastros_Produtores_Form,
            },
            {
              path: "propriedades",
              name: "cadastros-propriedades-table",
              meta: { label: "Propriedades" },
              component: Cadastros_Propriedades_Table,
            },
            {
              path: "propriedades/:action/:id?",
              name: "cadastros-propriedades-form",
              meta: { label: "Propriedades" },
              component: Cadastros_Propriedades_Form,
            },
            {
              path: "maquinas",
              name: "cadastros-maquinas-table",
              meta: { label: "Máquinas" },
              component: Cadastros_Maquinas_Table,
            },
            {
              path: "maquinas/:action/:id?",
              name: "cadastros-maquinas-form",
              meta: { label: "Máquinas" },
              component: Cadastros_Maquinas_Form,
            },]
        },
        // {
        //   path: "redistribuidores",
        //   name: "cadastro-redistribuidores-table",
        //   meta: { label: "Redistribuidores" },
        //   component: Cadastros_Redistribuidores_Table,
        // },
        // {
        //   path: "redistribuidores/:action/:id?",
        //   name: "cadastro-redistribuidores-form",
        //   meta: { label: "Redistribuidores" },
        //   component: Cadastros_Redistribuidores_Form,
        // },     
      
      ],
    },

    {

      // 
      path: "backoffice",
      name: "backoffice",
      meta: { label: "Backoffice" },
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "dashboard",
          meta: { label: "Dashboards" },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "backoffice-dashboards",
              meta: { label: "Dashboard" },
              component: Backoffice_Dashboard,
            },
          ]
        },


        // {
        //   path: "produtos",
        //   name: "backoffice-produtos_table",
        //   meta: { label: "Produtos Elipse" },
        //   component: Backoffice_Produtos_Table,
        // },
        // {
        //   path: "produtos/:action/:id?",
        //   name: "backoffice-produtos_form",
        //   meta: { label: "Produtos Elipse" },
        //   component: Backoffice_Produtos_Form,
        // },
        {
          path: "suporte",
          name: "Suporte",
          meta: { label: "Suporte" },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children:[
            // suportee

            {
              path: "segmentos",
              name: "backoffice-suporte-segmentos-table",
              meta: { label: "Segmento" },
              component: Backoffice_Segmentos_Table,
            },
            {
              path: "segmentos/:action/:id?",
              name: "backoffice-suporte-segmentos-form",
              meta: { label: "Segmento" },
              component: Backoffice_Segmentos_Form,
            },
            {
              path: "tipos-cliente",
              name: "backoffice-suporte-tipos-cliente-table",
              meta: { label: "Tipos de Cliente" },
              component: Backoffice_TiposCliente_Table,
            },
            {
              path: "tipos-cliente/:action/:id?",
              name: "backoffice-suporte-tipos-cliente-form",
              meta: { label: "Tipos de Cliente" },
              component: Backoffice_TiposCliente_Form,
            },
            {
              path: "tickets",
              name: "backoffice-suporte-tickets-table",
              meta: { label: "Ticket" },
              component: Backoffice_Tickets_Table,
            },
            {
              path: "tickets/:action/:id?",
              name: "backoffice-suporte-tickets-form",
              meta: { label: "Ticket" },
              component: Backoffice_Tickets_Form,
            },

            {
              path: "tipos-status-tickets",
              name: "backoffice-suporte-tipos-status-tickets-table",
              meta: { label: "Tipos Status Tickets" },
              component: Backoffice_Tipos_Status_Tickets_Table,
            },
            {
              path: "tipos-status-tickets/:action/:id?",
              name: "backoffice-tipos-status-tickets-form",
              meta: { label: "Tipos Status Tickets" },
              component: Backoffice_Tipos_Status_Tickets_Form,
            },

            {
              path: "tipos-status-agendamentos",
              name: "backoffice-suporte-tipos-status-agendamentos-table",
              meta: { label: "Tipos Status Agendamentos" },
              component: Backoffice_Tipos_Status_Agendamentos_Table,
            },
            {
              path: "tipos-status-agendamentos/:action/:id?",
              name: "backoffice-suporte-tipos-status-agendamentos-form",
              meta: { label: "Tipos Status Agendamentos" },
              component: Backoffice_Tipos_Status_Agendamentos_Form,
            },

            {
              path: "logs",
              name: "support-configuracoes-logs-table",
              meta: { label: "Logs" },
              component: Backoffice_Logs_Table,
            },
            {
              path: "logs/:action/:id?",
              name: "support-configuracoes-logs-form",
              meta: { label: "Logs" },
              component: Backoffice_Logs_Form,
            },

            {
              path: "acessos",
              name: "backoffice-suporte-acessos-table",
              meta: { label: "Acessos" },
              component: Backoffice_Acessos_Table,
            },
            {
              path: "acessos/:action/:id?",
              name: "backoffice-suporte-acessos-form",
              meta: { label: "Acessos" },
              component: Backoffice_Acessos_Form,
            },
          ]
          
        },
        {
          path: "configuracoes",
          name: "Configuracoes",
          meta: { label: "Configurações" },
          component: {
            render(c) {
              return c("router-view");
            },
          },
        
          children:[
            // configuracoes



            {
              path: "menu",
              name: "backoffice-configuracoes-menu-table",
              meta: { label: "Menu" },
              component: Backoffice_Menu_Table,
            },
            {
              path: "menu/:action/:id?",
              name: "backoffice-configuracoes-menu-form",
              meta: { label: "Menu" },
              component: Backoffice_Menu_Form,
            },
            {
              path: "perfil-menu",
              name: "backoffice-configuracoes-perfil-menu-table",
              meta: { label: "Perfil de Menu" },
              component: Backoffice_Perfil_Menu_Table,
            },          
            {
              path: "perfil-menu/:action/:id?",
              name: "backoffice-configuracoes-perfil-menu-form",
              meta: { label: "Perfil de Menu" },
              component: Backoffice_Perfil_Menu_Form,
            },
            {
              path: "usuario-master",
              name: "backoffice-configuracoes-usuario-master-table",
              meta: { label: "Usuário Master" },
              component: Backoffice_UsuarioMaster_Table,
            }, 
            {
              path: "usuario-master/config/:id?",
              name: "backoffice-configuracoes-usuario-master-config",
              meta: { label: "Usuário Master " },
              component: Backoffice_UsuarioMaster_Config,
            },         
            {
              path: "usuario-master/config/:empresaId/:action/:id?",
              name: "backoffice-configuracoes-usuario-master-form",
              meta: { label: "Usuário Master " },
              component: Backoffice_UsuarioMaster_Form,
            },
            
            
           
            
           
          ]
        },
        {
          path: "municipios",
          name: "backoffice-municipios-table",
          meta: { label: "Municípios" },
          component: Backoffice_Municipios_Table,
        },
        {
          path: "municipios/:action/:id?",
          name: "backoffice-municipios-form",
          meta: { label: "Municípios" },
          component: Backoffice_Municipios_Form,
        },

        {
          path: "indexadores",
          name: "backoffice-indexadores-table",
          meta: { label: "Indexadores" },
          component: Backoffice_Indexadores_Table,
        },
        {
          path: "indexadores/:action/:id?",
          name: "backoffice-indexadores-form",
          meta: { label: "Indexadores" },
          component: Backoffice_Indexadores_Form,
        },

        {
          path: "culturas",
          name: "backoffice-culturas-table",
          meta: { label: "Culturas" },
          component: Backoffice_Culturas_Table,
        },
        {
          path: "culturas/:action/:id?",
          name: "backoffice-culturas-form",
          meta: { label: "Culturas" },
          component: Backoffice_Culturas_Form,
        },

        {
          path: "motivos-recusa",
          name: "backoffice-motivos-recusa-table",
          meta: { label: "Motivos Recusa" },
          component: Backoffice_MotivosRecusa_Table,
        },
        {
          path: "motivos-recusa/:action/:id?",
          name: "backoffice-motivos-recusa-form",
          meta: { label: "Motivos Recusa" },
          component: Backoffice_MotivosRecusa_Form,
        },

        {
          path: "tipos-interacao",
          name: "backoffice-tipos-interacao-table",
          meta: { label: "Tipos Interação" },
          component: Backoffice_TiposInteracao_Table,
        },
        {
          path: "tipos-interacao/:action/:id?",
          name: "backoffice-tipos-interacao-form",
          meta: { label: "Tipos Interação" },
          component: Backoffice_TiposInteracao_Form,
        },

        {
          path: "unidades-medida",
          name: "backoffice-unidades-medida-table",
          meta: { label: "Unidades de Medida" },
          component: Backoffice_UnidadesMedida_Table,
        },
        {
          path: "unidades-medida/:action/:id?",
          name: "backoffice-unidades-medida-form",
          meta: { label: "Unidades de Medida" },
          component: Backoffice_UnidadesMedida_Form,
        },

        {
          path: "unidades-negocio",
          name: "backoffice-unidades-negocio-table",
          meta: { label: "Unidades de Negócio" },
          component: Backoffice_UnidadesNegocio_Table,
        },
        {
          path: "unidades-negocio/:action/:id?",
          name: "backoffice-unidades-negocio-form",
          meta: { label: "Unidades de Negócio" },
          component: Backoffice_UnidadesNegocio_Form,
        },

        {
          path: "formas-pagamento",
          name: "backoffice-formas-pagamento-table",
          meta: { label: "Formas de Pagamento" },
          component: Backoffice_FormasPagamento_Table,
        },
        {
          path: "formas-pagamento/:action/:id?",
          name: "backoffice-formas-pagamento-form",
          meta: { label: "Formas de Pagamento" },
          component: Backoffice_FormasPagamento_Form,
        },

        {
          path: "modos-pagamento",
          name: "backoffice-modos-pagamento-table",
          meta: { label: "Modos de Pagamento" },
          component: Backoffice_ModosPagamento_Table,
        },
        {
          path: "modos-pagamento/:action/:id?",
          name: "backoffice-modos-pagamento-form",
          meta: { label: "Modos de Pagamento" },
          component: Backoffice_ModosPagamento_Form,
        },


        // backoffice cadastros



        {
          path: "empresas-antigo",
          name: "backoffice-empresas-antigo-table",
          meta: { label: "Empresas Antigo" },
          component: Backoffice_Empresas_Antigo_Table,
        },
        {
          path: "empresas-antigo/:action/:id?",
          name: "backoffice-empresas-antigo-form",
          meta: { label: "Empresas Antigo" },
          component: Backoffice_Empresas_Antigo_Form,
        },

        {
          path: "empresas",
          name: "backoffice-empresas-table",
          meta: { label: "Empresas" },
          component: Backoffice_Empresas_Table,
        },
              
        {
          path: "empresas/:action/:id?",
          name: "backoffice-empresas-form",
          meta: { label: "Empresas" },
          component: Backoffice_Empresas_Form,
        },

        {
          path: "produtos-usuarios",
          name: "backoffice-produtos-usuarios-table",
          meta: { label: "Produtos e Usuários" },
          component: Backoffice_ProdutosUsuarios_Table,
        },
        {
          path: "produtos-usuarios/:action/:id?",
          name: "backoffice-produtos-usuarios-form",
          meta: { label: "Produtos e Usuários" },
          component: Backoffice_ProdutosUsuarios_Form,
        },     
        
        {
          path: "usuarios",
          name: "backoffice-usuarios-table",
          meta: { label: "Usuários" },
          component: Backoffice_Usuarios_Table,
        },
        {
          path: "usuarios/:action/:id?",
          name: "backoffice-usuarios-form",
          meta: { label: "Usuários" },
          component: Backoffice_Usuarios_Form,
        },

        {
          path: "perfil-acesso",
          name: "backoffice-perfil-acesso-table",
          meta: { label: "Perfil de Acesso" },
          component: Backoffice_PerfilAcesso_Table,
        },
        {
          path: "perfil-acesso/config/:id?",
          name: "backoffice-perfil-acesso-config",
          meta: { label: "Perfil de Acesso" },
          component: Backoffice_PerfilAcesso_Config,
        },
        {
          path: "perfil-acesso/:action/:id?",
          name: "backoffice-perfil-acesso-form",
          meta: { label: "Perfil de Acesso" },
          component: Backoffice_PerfilAcesso_Form,
        },

        {
          path: "perfil-config",
          name: "backoffice-perfil-config-table",
          meta: { label: "Configurações de Perfil" },
          component: Backoffice_PerfilConfig_Table,
        },
        {
          path: "perfil-config/:action/:id?",
          name: "backoffice-perfil-config-form",
          meta: { label: "Configurações de Perfil" },
          component: Backoffice_PerfilConfig_Form,
        },


        //configuracoes


        {
          path: "tipo-status-item-negociado",
          name: "backoffice-tipo-status-item-negociado-table",
          meta: { label: "Tipo de Status de Item Negociado" },
          component: Backoffice_TipoStatusItemNegociado_Table,
        },
        {
          path: "tipo-status-item-negociado/:action/:id?",
          name: "backoffice-tipo-status-item-negociado-form",
          meta: { label: "Tipo de Status de Item Negociado" },
          component: Backoffice_TipoStatusItemNegociado_Form,
        },       

        {
          path: "fabricantes",
          name: "backoffice-fabricantes-table",
          meta: { label: "Fabricantes" },
          component: Backoffice_Fabricantes_Table,
        },
        {
          path: "fabricantes/:action/:id?",
          name: "backoffice-fabricantes-form",
          meta: { label: "Fabricantes" },
          component: Backoffice_Fabricantes_Form,
        },

        {
          path: "familias",
          name: "backoffice-familias-table",
          meta: { label: "Familias" },
          component: Backoffice_Familias_Table,
        },
        {
          path: "familias/:action/:id?",
          name: "backoffice-familias-form",
          meta: { label: "Familias" },
          component: Backoffice_Familias_Form,
        },

        {
          path: "portifolio",
          name: "backoffice-portifolio-table",
          meta: { label: "Portifolios" },
          component: Backoffice_Portifolio_Table,
        },
        {
          path: "portifolio/:action/:id?",
          name: "backoffice-portifolio-form",
          meta: { label: "Portifolios" },
          component: Backoffice_Portifolio_Form,
        },

        {
          path: "marcas",
          name: "backoffice-marcas-table",
          meta: { label: "Marcas" },
          component: Backoffice_Marcas_Table,
        },
        {
          path: "marcas/:action/:id?",
          name: "backoffice-marcas-form",
          meta: { label: "Marcas" },
          component: Backoffice_Marcas_Form,
        },

        {
          path: "categorias",
          name: "backoffice-categorias-table",
          meta: { label: "Categorias" },
          component: Backoffice_Categorias_Table,
        },
        {
          path: "categorias/:action/:id?",
          name: "backoffice-categorias-form",
          meta: { label: "Categorias" },
          component: Backoffice_Categorias_Form,
        },

        {
          path: "grupos",
          name: "backoffice-grupos-table",
          meta: { label: "Grupos" },
          component: Backoffice_Grupos_Table,
        },
        {
          path: "grupos/:action/:id?",
          name: "backoffice-grupos-form",
          meta: { label: "Grupos" },
          component: Backoffice_Grupos_Form,
        },

        {
          path: "produtos",
          name: "backoffice-produtos-table",
          meta: { label: "Produtos Elipse" },
          component: Backoffice_Produtos_Elipse_Table,
        },
        {
          path: "produtos/:action/:id?",
          name: "backoffice-produtos-form",
          meta: { label: "Produtos Elipse" },
          component: Backoffice_Produtos_Elipse_Form,
        },

        {
          path: "modulos",
          name: "backoffice-modulos-table",
          meta: { label: "Modulos" },
          component: Backoffice_Modulos_Table,
        },
        {
          path: "modulos/:action/:id?",
          name: "backoffice-modulos-form",
          meta: { label: "Modulos" },
          component: Backoffice_Modulos_Form,
        },

        {
          path: "funcionalidades",
          name: "backoffice-funcionalidades-table",
          meta: { label: "Funcionalidades" },
          component: Backoffice_Funcionalidades_Table,
        },
        {
          path: "funcionalidades/:action/:id?",
          name: "backoffice-funcionalidades-form",
          meta: { label: "Funcionalidades" },
          component: Backoffice_Funcionalidades_Form,
        },

        {
          path: "permissoes",
          name: "backoffice-permissoes-table",
          meta: { label: "Permissoes" },
          component: Backoffice_Permissoes_Table,
        },
        {
          path: "permissoes/:action/:id?",
          name: "backoffice-permissoes-form",
          meta: { label: "Permissoes" },
          component: Backoffice_Permissoes_Form,
        },
      ],
    },

    ]

  },


  {
    path: "*",
    name: "Page404",
    component: Page404,
  },

  ]
}

// Check local storage to handle refreshes
if (window.localStorage) {
  if (
    window.localStorage.getItem("userInfo") &&
    window.localStorage.getItem("token")
  ) {
    if (
      JSON.stringify(store.state.userInfo) !==
      window.localStorage.getItem("userInfo") ||
      store.state.token !== window.localStorage.getItem("token")
    ) {
      store.commit(
        "SET_USER",
        JSON.parse(window.localStorage.getItem("userInfo"))
      );
      store.commit("SET_TOKEN", window.localStorage.getItem("token"));
      store.commit("SET_PERMISSOES", window.localStorage.getItem("menu"));
    }
  }
}

// Some middleware to help us ensure the user is authenticated.
// Interceptor rotas
router.beforeEach(async (to, from, next) => {
  // Define Titulo
  // document.title =  '...::: Clarivi - ' + (to.name || 'Integração') +

  if (to.matched.some((record) => record.meta.auth)) {
    if (
      (router.app.$store.state.token === null ||
        router.app.$store.state.token === "null") &&
      (store.state.token === null || store.state.token === "null")
    ) {
      next({
        path: "login",
        query: { redirect: to.fullPath },
      });
    } else {
      Auth.refreshMenu();
      next();
    }
  } else {
    next();
  }
});

export default router;